
// custom styling over jquery ui slider
#bootstrapoverride {
	.toolbar {
		position: absolute;
		top: 0;
		left: 0;
		width: 300px;
		min-width: 200px;
		margin: 150px 40px 50px 40px;
		transition: transform .5s ease-in-out;
		// transition: margin-left .5s ease-in-out

		&.hidden-toolbar {
			transform: translate3d(-340px,0,0);
 }			// margin-left: -340px

		h5 {
			color: white;
			font-weight: bold;
			margin-bottom: 15px; }

		.firsttime-guide-cover {
			z-index: 100;
			position: absolute;
			top: -40px;
			height: 150%;
			width: 100%; }

		.toolbar-title {
			position: absolute;
			top: -40px;
			left: 0;
			font-size: 18px; }


		.toolbar-section {
			padding: 20px;
			border-bottom: solid 1px #6b7389;
			border-style: solid;
			border-color: rgba(163,173,203,.6);
			border-width: 1px 1px 0 1px; }

		.aggregate-level-filter {
			border-bottom: none; }


		.double-chevron {
			position: absolute;
			top: -30px;
			right: 0;
			width: 24px;
			padding: 2px 4px;
			transition: all .5s ease-in-out;

			&:hover {
				cursor: pointer; }

			span:first-of-type {
				margin-right: 9px; }

			&.left {
				transform: rotate(180deg); }

			&.extend-right {
				right: -30px; } } }


	// jquery ui slider custom styling
	.toolbar .year-slider h5,
	.toolbar .month-slider h5 {
		margin-bottom: 55px; }


	#year-slider,
	#month-slider {
		margin: 0 auto; }

	.ui-slider-horizontal {
		height: 3px;
		width: 85% !important;
		border: none;
		background-color: #bcc8c9;

		.ui-slider-range {
			background-color: $toolbar-red; }

		.ui-slider-handle {
			top: -6px;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			background-color: white;
			border: 3px solid $toolbar-red;

			&:focus {
				outline: none; } } }


	.slider-val-display {
		position: absolute;
		top: -32px;
		width: 46px;
		margin-left: -18px;

		input {
			width: 100%;
			height: 18px;
			border: 0;
			border-radius: 1px;
			background-color: $toolbar-red;
			padding: 2px 0 1px;
			text-align: center;
			font-size: 12px;
			font-weight: bold; } }


	.bubble-arrow:after {
		content: "";
		position: absolute;
		top: 20px;
		left: 18px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 5px;
		border-color: $toolbar-red transparent transparent transparent; }


	.slider-scale {
		position: relative;
		width: 85%;
		margin: 10px auto 50px;

		.slider-scale-val {
			position: absolute;
			top: 0;
			width: 40px;
			background-color: transparent;
			border: none;
			text-align: center;
			font-size: 10px;
			color: rgba(224,224,224,.5);

			&.minval {
				left: -20px; }
			&.maxval {
				right: -20px; } }

		.scale-start,
		.scale-end {
			position: absolute;
			top: -18px;
			width: 1px;
			height: 13px;
			background-color: #bcc8c9; }

		.scale-start {
			left: 0; }

		.scale-end {
			right: 0; } }



	// toolbar radio buttons custom styling
	.toolbar .time-filter,
	.toolbar .region-filter {
		input[type="radio"] {
			display: none; }

		label:not(.file-upload-btn) {
			position: relative;
			display: inline-block;
			padding-left: 30px;
			line-height: 20px;
			margin: 0 0 5px 15px; }

		// empty circle
		label:not(.file-upload-btn):before {
			content: "";
			position: absolute;
			top: 2px;
			left: 0;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			background-color: transparent;
			border: 1px solid white; }

		//circle with red dot
		input:checked + label:not(.file-upload-btn):after {
			content: "";
			position: absolute;
			top: 6px;
			left: 4px;
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: $toolbar-red; } }


	// toolbar select dropdown
	.toolbar select {
		width: 100%;
		height: 30px;
		padding: 5px 9px;
		border: solid 1px #e1e1e1;
		border-radius: 2px;
		appearance: none;
		background-color: transparent;
		cursor: pointer;
		outline: none;
		font-size: 14px;
		color: rgba(224,224,224,.5); }


	.dropdown-wrapper {
		position: relative;
		width: 90%;
		height: 30px;
		margin: 0 auto 10px;

		//arrow
		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: 15px;
			top: 20px;
			margin-top: -6px;
			border-width: 6px 6px 0 6px;
			border-style: solid;
			border-color: #e0e0e0 transparent; } }



	// button
	.btn {
		display: inline-block;
		appearance: none;
		border: none;
		padding: 5px 20px;
		background-color: $toolbar-red;
		color: white;
		font-size: 18px;
		text-align: center;

		&:hover {
			cursor: pointer; }


		&.build-chart {
			width: 100%;
			padding: 10px 0;
			letter-spacing: 5px; } }


	.chevron-right {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 7px;
			border-color: transparent transparent transparent white; }
		&:after {
			content: "";
			position: absolute;
			top: 2px;
			left: -1px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 5px;
			border-color: transparent transparent transparent $bg-gray; } }


	// animated hamburger to X menu icon
	$button-size: 30px;
	$transition: .3s;
	$color: white;

	.lines-button {
		position: fixed;
		top: 30px;
		left: 60px;
		z-index: 9999;
		display: inline-block;
		width: 50px;
		height: 50px;
		padding: 8px 10px 15px;
		background: transparent;
		transition: .3s;
		cursor: pointer;
		user-select: none;
		appearance: none;
		border: none;
		opacity: 1;

		&.intro {
			top: 60px;
			left: initial;
			right: 80px; }

		&:focus {
			outline: 0; }

		&.hidden {
			display: none; } }


	@mixin line {
		display: inline-block;
		width: $button-size;
		height: 3px;
		background: $color;
		transition: $transition;
		border-radius: 3px; }


	.lines {
		@include line; //create middle line
		position: relative;

		/*create the upper and lower lines as pseudo-elements of the middle line*/
		&:before,
		&:after {
			@include line;
			position: absolute;
			left: 0;
			content: '';
			transform-origin: $button-size/14 center; }

		&:before {
			top: 10px; }
		&:after {
			top: -10px; } }



	.lines-button.intro .lines {
		height: 2px;

		&:before {
			top: 6.6px;
			height: 1.9px;
			width: 20.6px;
			right: 0;
			left: initial; }
		&:after {
			top: -6.6px;
			height: 1.9px; } }


	//animating
	.lines-button.x.close .lines {
		/*hide the middle line*/
		background: transparent;

		/*overlay the lines by setting both their top values to 0*/
		&:before,
		&:after {
			transform-origin: 50% 50%;
			top: 0;
			width: $button-size; }

		// rotate the lines to form the x shape
		&:before {
			transform: rotate3d(0,0,1,45deg); }
		&:after {
			transform: rotate3d(0,0,1,-45deg); } }
	// end of animated hamburger icon



	.btn-load {
		background-color: #ff6560;
		border: none;
		border-radius: 4px;
		float: right; }

	.file-upload {
		//display: inline
		//width: 150px
		display: none; }

	.file-upload-btn {
		background-color: #8760ff;
		border: none;
		border-radius: 4px;
		float: right;
		height: 30px;
		line-height: 30px;
		padding: 0 20px;
		width: 76px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&.has-file {
			padding: 0 10px; }

		&:hover {
			position: absolute;
			right: 21px;
			width: auto;
			padding: 0 20px;
			transition: all .2s; } } }













