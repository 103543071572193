
body#bootstrapoverride {
	position: relative;
	background-color: $bg-gray;
	z-index: -99999;
	min-width: 1200px;

	//customize the color of imarket background
	&.imarket {
		background: $imarket-bg-gradient;
		background-color: $bg-gray; }

	//customize the color of introduction page background
	&.gradient-intro-background {
		background-color: none;
		background: $intro-bg-gradient; }

	button {
		font-family: $primary-style; }

	.main-content-wrapper {
		position: relative; }


	.chart-outer-wrapper {
		position: absolute;
		top: 0;
		left: 380px;
		width: calc(100% - 380px);//toolbar width with margins
		margin-top: 100px;
		transition: transform .4s .1s ease-in-out;

		h1 {
			font-size: 25px;
			text-align: center;
			margin-bottom: 50px;
			line-height: 32px;

			span {
				font-size: 25px; } }

		.chart-remark {
			position: relative;
			height: 55px;

			.chart-remark-inner {
				position: absolute;
				width: 250px;
				right: 50px;
				text-align: left;


				p {
					font-size: 18px; } } }

		.firsttime-guide {
			z-index: 1000;
			font-family: "PingFangTC";
			position: absolute;
			height: 120%;
			width: 100%;
			top: -100px;
			padding-left: 29px;
			background-color: rgba(33, 40, 49, .86);

			.guide-title {
				margin: 70px 0px 0px;
				font-size: 20px; }

			.guide-steps {
				margin: 50px 0px;
				top: 49px; }

			p {
				margin: 15px 0px;
				font-size: 20px; } }



		&.expanded {
			transform: translate3d(-300px,0,0); }


		&.ranking-outer-wrapper {
			right: 0;
			margin: 100px auto 0; } }

	.dark-button {
		width: 85px;
		height: 30px;
		font-size: 16px;
		border: solid 1px white;
		border-radius: 2px;
		background-color: transparent;
		cursor: pointer;
		color: white;
		text-align: center;
		line-height: 0px;
		margin: 20px auto;

		&:hover {
			background-color: #3b445c; }

		&.active {
			background-color: #3b445c; } }


	// iindustry styles
	&.iindustry {
		background: none;
		background-color: rgb(48, 51, 74); }


	.iindustry-nav {
		background-color: rgba(0, 0, 0, .1);
		height: 50px;
		padding-top: 15px;

		&.darker {
			background-color: #101634; }

		.inner-wrapper {
			max-width: 1200px;
			margin: 0 auto;
			padding: 0 20px; }


		.iindustry-logo {
			position: relative;
			left: 173px;
			top: 0px;
			margin-left: 25px;

			a {
				font-size: 24px;
				font-family: "CarroisGothicSC";
				letter-spacing: 1px;
				text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); } }

		.desktop-nav {
			position: static;
			float: right;
			width: auto;
			margin-right: 180px;

			li:first-of-type {
				margin-right: 60px; }

			a {
				font-size: 14px;
				font-weight: bold;
				color: #70748c;
				transition: color .2s;

				&.active {
					position: relative;
					color: white;

					&:after {
						content: "";
						position: absolute;
						bottom: -15px;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: #50e3c2; } }

				&:hover {
					color: white; } } } }



	.iindustry-body-wrapper {
		position: relative;
		padding-top: 250px;

		.page-name {
			position: absolute;
			top: 100px;
			left: 0;
			right: 0;
			font-size: 22px;
			line-height: 30px;
			font-weight: bold;
			text-align: center;

			span {
				font-size: 22px; } } }


	.chart-quick-nav {
		position: absolute;
		top: 160px;
		left: 0;
		right: 0;
		width: 248px;
		margin: 0 auto;

		li {
			display: inline-block;
			position: relative;
			width: 80px;
			font-size: 14px;
			color: rgba(112, 117, 141, .6);
			text-align: center;
			transition: color .3s;
			font-weight: bold;

			&:after {
				content: "";
				position: absolute;
				bottom: -5px;
				left: -1px;
				width: calc(100% + 2px);
				height: 1px;
				background-color: rgba(112, 117, 141, .6);
				transition: background-color .3s; }

			&.active {
				color: white;

				&:after {
					background-color: #50e3c2; } }

			&:hover {
				cursor: pointer; } } }



	$gradient-red: linear-gradient(to top, #fe9957 0%, #ea7777 100%);
	$gradient-blue: linear-gradient(to top, #31d6dc 0%, #209edc 100%);
	$gradient-yellow: linear-gradient(to top, #ffe38c 0%, #ffd34d 100%);

	// .bar
	// 	fill: url(#gradient-red)

	// .bar2
	// 	fill: url(#gradient-blue)


	.bar-chart-legend {
		position: absolute;
		top: 210px;
		left: 0;
		right: 0;
		width: 870px;
		margin: 0 auto;

		.float-right {
			display: flex;
			float: right;
			margin-left: 45px; }

		.circle {
			position: relative;
			display: inline-block;
			width: 26px;
			height: 26px;
			border: 2px solid #babed4;
			border-radius: 50%;
			margin-right: 8px;

			&:after {
				content: "";
				position: absolute;
				top: 3px;
				left: 3px;
				width: 16px;
				height: 16px;
				border-radius: 50%; }

			&.red:after {
				background: $gradient-red; }
			&.blue:after {
				background: $gradient-blue; }
			&.yellow:after {
				background: $gradient-yellow; } }

		p {
			display: inline-block;
			font-size: 18px;
			font-weight: bold;
			line-height: 29px; } }


	.horizontal-stacked-container {
		margin-top: 80px; }


	.horizontal-chart-legend {
		position: absolute;
		top: 260px;
		left: 0;
		right: 0;
		width: 900px;
		margin: 0 auto; }

	.line-chart-container {
		margin-top: 80px; }


	.iindustry-chart-outer-wrapper {
		// position: absolute
		// top: 220px
		// left: 0
		// right: 0

		width: 1000px;//toolbar width with margins
		margin: 0px auto 70px;

		// &.horizontal
 }		// 	top: 280px

	.chart-container {
		// position: absolute
		// top: 1020px
		// left: 0
		// right: 0
		margin: 0 auto 40px;

		svg {
			margin: 0px auto;
			display: block; }

		p {
			text-align: center; }

		table {
			width: 770px;
			background-color: transparent;
			margin-bottom: 100px; }

		td {
			border: none;
			font-weight: bold;
			padding: 5px 10px; }

		thead {
			background-color: rgba(18, 27, 38, .8);
			color: rgba(255, 255, 255, .9);
			td {
				position: relative;
				padding: 5px 0;
				border-bottom: 1px solid rgba(255, 255, 255, .5);
				width: 100px;

				&:nth-child(1) {
					width: 130px; }

				&:not(:last-of-type):after {
					content: "";
					position: absolute;
					top: 8px;
					right: 0;
					width: 1px;
					height: 40px;
					background-color: rgba(255, 255, 255, .5); }

				&:hover {
					cursor: pointer; } }
			tr {
				&:nth-child(2) {
					td {
						&:after {
							background-color: rgba(255, 255, 255, 0); } } } } }


		tbody>tr:first-of-type td {
			padding-top: 20px; }
		tbody {
			max-height: 500px;
			overflow-x: hidden;
			overflow-y: auto;

			tr:nth-child(even) {
				background-color: $table-odd-row; }

			tr {
				td {
					text-align: right;
					width: 100px;
					&:nth-child(1) {
						text-align: center;
						width: 130px; } } } } }

	.iindustry-table {
		tbody {
			display: block; } }

	.table-source {
		position: relative;
		width: 160px;
		top: -135px;
		margin: auto; }

	.line-chart-legend {
		position: absolute;
		top: 260px;
		left: 0;
		right: 0;
		width: 600px;
		margin: 0 auto; }

	.chart-controls {
		// position: absolute
		// top: 960px
		// left: 0
		// right: 0
		width: 770px;
		height: 90px;
		margin: 0 auto;

		.vertical-separate {
			font-size: 18px;
			height: 30px;
			line-height: 30px;
			margin: 0 14px; }

		.btn {
			line-height: 30px;
			background: $gradient-blue;
			padding: 0 30px;
			border-radius: 2px;
			height: 30px;

			&.file-upload-btn {
				background: linear-gradient(to top, #31d6dc 0%, #50e3c2 100%);
				float: right;
				height: 30px;
				line-height: 30px;
				padding: 0 30px;
				width: 96px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&.has-file {
					padding: 0 15px; }

				&:hover {
					position: absolute;
					width: auto;
					padding: 0 30px;
					transition: all .2s; } } }

		.dropdown-wrapper,
		.btn {
			float: left; }

		.dropdown-wrapper {
			width: auto;
			margin-right: 10px;

			&:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				right: 10px;
				top: 20px;
				margin-top: -6px;
				border-width: 6px 6px 0 6px;
				border-style: solid;
				border-color: white transparent; }

			select {
				width: 100%;
				height: 30px;
				padding: 2px 40px 2px 5px;
				border: solid 1px white;
				border-radius: 2px;
				appearance: none;
				background-color: transparent;
				cursor: pointer;
				outline: none;
				font-size: 14px;
				color: white; } }

		.hidden {
			display: none; } }

	.bar-chart-container {
		max-height: 600px;
		overflow-x: hidden;
		overflow-y: auto;
		display: flex; }

	.iindustry-treemap-container {
		position: relative; } }







