#bootstrapoverride {
	.home-container {
		position: relative;
		width: 100%;
		padding-bottom: 30px;

		.video-bg {
			width: 100%;
			height: 750px;
			background: linear-gradient(to bottom, rgba(22, 34, 52,.4) 0%, rgba(14, 24, 38,.4) 70%, rgba(14, 24, 38, .95) 90%, rgba(14, 24, 38,1) 100%); }


		.home-headers {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 700px;

			h1, span {
				color: rgba(255,255,255,1); }

			.title {
				position: absolute;
				display: block;
				top: 230px;
				left: 0;
				right: 0;
				width: 400px;
				letter-spacing: 1px;
				margin: 0 auto;
				text-align: center;

				span.big {
					font-size: 35px;
					font-weight: bold;
					text-shadow: $text-shadow; }

				span.small {
					display: inline-block;
					font-family: $secondary-style;
					font-weight: normal;
					font-size: 24px;
					margin-top: 15px;
					text-shadow: $text-shadow;

					&:after {
						content: "";
						position: absolute;
						bottom: -30px;
						left: 0;
						right: 0;
						margin: 0 auto;
						width: 30px;
						height: 2px;
						background-color: white; } } }

			.sub-title {
				position: absolute;
				top: 340px;
				left: 0;
				right: 0;
				margin: 0 auto;
				text-align: center;
				font-size: 16px; }


			.taiwan-info-container {
				position: absolute;
				display: flex;
				align-content: space-between;
				top: 450px;
				left: 0;
				right: 0;
				width: 900px;
				margin: 0 auto;

				&>div {
					flex: 1 1 auto;
					width: 25%;
					text-align: center; }

				.small,
				.large {
					display: block;
					text-shadow: $text-shadow; }

				.small {
					font-size: 20px;
					text-shadow: $text-shadow2; }
				.large {
					font-size: 36px;
					font-family: $secondary-style;
					margin-top: 20px;
					color: rgba(255,255,255,1);
					text-shadow: $text-shadow3; } } }


		.home-content {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			max-width: 1400px;
			padding: 30px 40px;
			margin: 0 auto;

			.column {
				flex: 1 1 auto;
				width: 20%;
				min-width: 200px;
				margin: 0 20px;

				h2, h3 {
					position: relative; }

				h2,h3 {
					&:after {
						content: "";
						position: absolute;
						bottom: -5px;
						left: 0;
						width: 20px;
						height: 2px;
						background-color: white; } } }

			.column-main {
				flex: 1 1 auto;
				width: 50%;
				margin: 0 50px 160px 20px; }

			.column-img {
				position: relative;
				flex: 1 1 auto;
				width: 30%;
				margin-top: -80px;
				text-align: center;
				opacity: .9; }


			h2 {
				font-size: 36px;
				font-weight: bold;
				margin-bottom: 20px; }

			h3 {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 20px; } }


		.copyright {
			text-align: center;
			margin-top: 145px;
			width: 100% {
	    background: rgba(33, 40, 49,1);
	    padding: 30px 0 0 0;
	    text-align: center;
	    color: white;
	    font-size: 0.81em;
	    line-height: 20px;
	    margin-top: 100px; } } } }





//BREAKPOINTS
