#bootstrapoverride .body-wrapper {
	.intro-wrapper {
		position: relative;
		height: 100vh;
		overflow: hidden;

		.particle-container {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			width: 600px;
			height: 600px;
			margin: 0 auto;
			border-radius: 50%;
			width: 100%;
			height: 100vh;
			z-index: -9999; }

		.slogan {
			position: fixed;
			top: 40vh;
			left: 30%;

			span {
				font-family: $secondary-style;
				font-size: 24px;
				line-height: 30px; }

			.italic {
				font-style: italic; }

			.heavy {
				font-weight: bold; } }


		.down-arrow {
			display: block;
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			width: 85px;
			height: 62px;
			margin: 0 auto;
			z-index: 1;
			transition: transform .3s;
			cursor: pointer;

			&:before {
				content: "Learn More";
				position: absolute;
				top: 0;
				left: 0;
				color: white;
				font-size: 14px;
				letter-spacing: .8px;
				font-weight: 500;
				font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
				text-align: center;
				animation-name: textJump;
				animation-duration: 2s;
				animation-iteration-count: infinite; }

			&:after {
				content: "";
				position: absolute;
				top: 32px;
				left: 50%;
				border-left: solid 1px white;
				height: 30px;
				width: 1px;
				animation-name: arrowWave;
				animation-duration: 2s;
				animation-iteration-count: infinite; }

			&.remove {
				transform: translate3d(0, 10vh, 0); } }



		.projects-mask {
			position: fixed;
			top: 110vh;
			left: 0;
			right: 0;
			min-width: 1200px;
			width: 100vw;
			min-height: 100vh;
			overflow: hidden;
			margin: 0 auto;
			background-color: rgba(0,0,0,.8);
			transition: transform .5s;

			&.move-to-view {
				transform: translate3d(0px,-110vh,0) !important; }

			.projects-wrapper {
				min-width: 100vw;
				margin: 0px auto 0px;
				height: 100vh;
				overflow: auto;
				padding: 25vh 15vw 10vh 15vw;
				display: flex;
				flex-wrap: wrap;

				.news {
					margin: 0px 70px 0px 70px;
					width: 100%;
					height: 75vh; } }

			.project-box {
				position: relative;
				float: left;
				width: 300px;
				height: 400px;
				margin: 0 15px 15px;
				padding: 95px 25px;
				border: solid 1px rgba(255, 255, 255, .9);

				// &:before
				// 	content: ""
				// 	position: absolute
				// 	top: -56px
				// 	left: -56px
				// 	width: 0
				// 	height: 0
				// 	border-style: solid
				// 	border-width: 55px
				// 	border-color: #2ecbd0 transparent transparent transparent
				// 	opacity: 0
				// 	transform: rotate(135deg)
				// 	transition: opacity .3s

				// &:after
				// 	content: ""
				// 	position: absolute
				// 	bottom: -56px
				// 	right: -56px
				// 	width: 0
				// 	height: 0
				// 	border-style: solid
				// 	border-width: 55px
				// 	border-color: #2ecbd0 transparent transparent transparent
				// 	opacity: 0
				// 	transform: rotate(-45deg)
				// 	transition: opacity .3s


				.triangle {
					width: 130px;
					height: 130px;
					position: absolute;
					clip: rect(auto, 180px, auto, 100px);
					transform: rotate(-135deg);
					opacity: 0;
					transition: opacity .3s; }

				.triangle::after {
					content: "";
					position: absolute;
					top: 10px;
					bottom: 10px;
					left: 10px;
					right: 10px;
					background: linear-gradient(to top left, #209edc 0%, #31d6dc 40%);
					transform: rotate(-45deg); }

				.triangle-1 {
					top: -11px;
					left: -11px; }

				.triangle-2 {
					bottom: -11px;
					right: -11px;
					transform: rotate(45deg); }



				h2 {
					font-size: 18px;
					line-height: 22px;
					letter-spacing: 1px;
					margin-bottom: 15px;
					font-family: "CarroisGothicSC", "微軟正黑體"; }

				p {
					line-height: 22px;
					font-family: $secondary-style; }

				.more {
					position: absolute;
					bottom: 30px;
					left: 25px;
					color: #2ecbd0;
					font-family: $secondary-style;
					font-weight: bold;
					opacity: 0;
					transition: opacity .3s; }

				&:hover .more,
				&:hover .triangle {
					opacity: 1; } }


			.project-box.inactive {
				pointer-events: none; }

			.footer, .footer a {
				width: 100%;
				margin: 5vh 0 -5vh 0;
				text-align: center;
				letter-spacing: 1px;
				font-size: 18px;
				font-family: $secondary-style; }

			.copy {
				opacity: .9;
				width: 100%;
				margin: 10vh 0 calc(-10vh + 26px) 0;
				text-align: center;
				letter-spacing: 1px;
				font-size: 18px;
				font-family: $secondary-style; } }

		.main-container {
			width: 874px;
			margin: 170px auto; }

		.about-us {
			margin: calc(50vh - 70px) auto;

			p {
				line-height: 1.86;
				letter-spacing: .8px;
				text-align: justify; } }

		.news {

			table {
				margin: 0; }

			table, td {
				padding: 0;
				border: none;
				background-color: transparent; }

			td {
				text-align: left;
				line-height: 14px;
				letter-spacing: .8px;

				&:first-of-type {
					padding-right: 30px; }

				&:nth-of-type(2) {
					width: 701px;
					padding-bottom: 15px; }

				a {
					text-decoration: underline;

					&:after {
						position: absolute;
						top: 0;
						right: -60px;
						content: "More";
						width: 55px;
						height: 24px;
						background-color: $more-red;
						border-radius: 3px;
						text-align: center;
						line-height: 1.71;
						font-family: "CarroisGothicSC", "微軟正黑體"; } } }

			tr {
				margin-bottom: 15px; } }

		.iresource {
			display: flex;
			flex-direction: column;

			.link-container {
				width: 199px;
				height: 170px;
				margin: 0 24px 50px -15px;

				img {
					width: 100%;
					margin-bottom: 6px;
					vertical-align: top; }

				.name {
					width: 100%;
					height: 84px;
					line-height: 1.5; } } }

		.description {
			.video-container {
				margin: 0 0 0px 50px;
				min-height: 100px;
				overflow: hidden;
				iframe {
					width: 375px;
					height: 211px;
					float: left;
					margin: 9px; } }
			table {
				width: calc(100% - 50px);
				margin: 0 0 60px 50px;
				background-color: transparent;
				border: none;
				table-layout: fixed;

				th, td {
					background-color: transparent;
					border: none;
					text-align: left;
					padding: 8px;
					word-break: break-all; }

				&.resource th, &.resource td {
					&:first-child {
						width: 30%; }

					&:nth-child(2) {
						width: 20%; }

					&:nth-child(3) {
						width: 30%; }

					&:nth-child(4) {
						width: 20%; } }

				&.hscode th, &.hscode td {
					&:first-child {
						width: 30%; }

					&:nth-child(2) {
						width: 55%; }

					&:nth-child(3) {
						width: 15%; } }

				&.hscode caption {
					color: white;
					font-weight: bold; }

				th {
					border-bottom: solid 1px rgba(255, 255, 255, 0.37); }

				td.indent {
					padding-left: 40px; }

				td {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap; }

				td, tr {
					transition: all .2s; }

				tbody tr:hover {
					background-color: rgba(13, 16, 20, 0.67);

					.too-long-code {
						text-decoration: underline;
						cursor: pointer;

						&.all {
							text-decoration: none;
							white-space: normal; } } } }

			.illustation {

				tbody tr:hover {
					background-color: transparent; }

				img {
					width: 452px;
					height: auto; }


				tbody {
					tr {
						padding: 0px 40px 30px 0px; }
					td {
						vertical-align: middle;
						line-height: 1.86;
						letter-spacing: 0.8px;
						&:first-child {
							width: 500px; } } }

				.title {
					font-size: 14px;
					font-weight: bold;
					line-height: 1.86;
					letter-spacing: 0.8px; } } }

		.title {
			font-size: 24px;
			letter-spacing: 1.3px;
			margin-bottom: 28px;

			.itbd-logo {
				font-size: 24px;
				letter-spacing: 1.3px;
				font-family: "CarroisGothicSC", "微軟正黑體"; } } } }

.down-arrow-2 {
	display: block;
	position: fixed;
	bottom: 60px;
	left: 0;
	right: 0;
	width: 57px;
	height: 62px;
	margin: 0 auto;
	z-index: 1;
	transition: transform .3s;
	cursor: pointer;

	&:before {
		content: "Project";
		position: absolute;
		top: 0;
		left: 0;
		color: white;
		font-size: 14px;
		letter-spacing: .8px;
		font-weight: 500;
		font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
		text-align: center;
		animation-name: textJump;
		animation-duration: 2s;
		animation-iteration-count: infinite; }

	&:after {
		content: "";
		position: absolute;
		top: 32px;
		left: 50%;
		border-left: solid 1px white;
		height: 30px;
		width: 1px;
		animation-name: arrowWave;
		animation-duration: 2s;
		animation-iteration-count: infinite; }

	&.remove {
		transform: translate3d(0, 40vh, 0); } }




@media all and (max-width: 1200px) {
	#bootstrapoverride .body-wrapper {
		.intro-wrapper {
			.projects-mask {
				position: absolute; } } } }


@keyframes arrowWave {
	0% {
		height: 1px;
		top: 32px; }

	50% {
		height: 20px;
		top: 32px; }

	75% {
		height: 10px;
		top: 46px; }

	100% {
		height: 0px;
		top: 62px; } }

@keyframes textJump {
	0% {
		top: 0px; }

	75% {
		top: 0px; }

	95% {
		top: 10px; }

	100% {
		top: 0px; } }
