#bootstrapoverride {
	// .top-cell
	// 	stroke: $bg-gray
	// 	stroke-width: 4px

	// .mid-cell
	// 	stroke: $bg-gray
	// 	stroke-width: 2px

	// .bottom-cell
	// 	stroke: $bg-gray
	// 	stroke-width: 1px

	g text {
		stroke-width: 0;
		fill: white;
		pointer-events: none; }


	g rect {
		shape-rendering: crispEdges;
		opacity: .9;

		&:hover {
			opacity: 1; } }


	.chart-line {
		opacity: .9; }


	.line {
		fill: none;
		stroke: white;
		opacity: .7;
		stroke-width: 2px; }


	.tick text {
		font-size: 14px;
		fill: rgba(205,213,236,.8); }


	.tick line {
		opacity: .2; }


	.axis path,
	.axis line {
		fill: none;
		stroke: white;
		opacity: .2;
		shape-rendering: crispEdges; }


	.axis.x text {
		transform: translate(0, 10px); }

	.axis.y text {
		transform: translate(-10px, 0); }


	.y-label {
		transform: translate(-80px, 0) rotate(-90deg); }

	.label {
		fill: rgba(231,234,243,.8);
		font-size: 16px; }


	.area {
		opacity: .6; }


	.tooltip {
		position: absolute;
		max-width: 300px;
		padding: 20px;
		pointer-events: none;
		border-radius: 10px;
		background-color: rgba(0,0,0,.9);
		font-family: Helvetica;
		font-weight: bold; }

	.dot {
		opacity: .8; }


	.world-map-container path {
		stroke: grey;
		stroke-width: 0;
		fill: grey; }


	table {
		background-color: rgba(255,255,255,.2);
		margin: 0 auto;

		td {
			position: relative;
			border: 1px solid grey;
			padding: 5px 5px;
			text-align: center; } }


	.countr-ranking-container table td {
		position: relative;
		border: 1px solid grey;
		padding: 5px 5px;
		text-align: center;

		&:first-of-type {
			padding: 5px 20px 5px 40px; } }


	.chart-container-for-stacked-area {
		position: relative;
		padding-bottom: 50px;

		table {
			thead {
				background-color: $table-even-row; } } }


	.focus-line {
		fill: white;
		opacity: 0;
		pointer-events: none; }


	.f32 {
		display: inline;
		position: absolute;
		top: -4px;
		left: 0;

		li.flag {
			transform: scale(.8); } } }



