
// colors
$bg-gray: #212831;
$imarket-bg-gradient: linear-gradient(to bottom, rgba(22, 34, 52, 0) 0%, rgba(14, 24, 38, 1) 45%, rgba(22, 33, 49, 1) 100%);
$intro-bg-gradient: linear-gradient(to bottom, #19344a 0%, #1d2d3c 54%, #212831 100%);
$toolbar-red: #ff6560;
$table-odd-row: rgba(18, 27, 38, 0.6);
$table-even-row: #212831;
$more-red: #ff6560;



// font stack
@font-face {
	font-family: 'Noto';
	src: url('/asset/fonts/NotoSans-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Noto';
	src: url('/asset/fonts/NotoSans-Bold.ttf');
	font-weight: bold;
	font-style: normal; }

@font-face {
	font-family: 'Lato';
	src: url('/asset/fonts/Lato-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Lato';
	src: url('/asset/fonts/Lato-Bold.ttf');
	font-weight: bold;
	font-style: normal; }

@font-face {
	font-family: 'AlegreyaSansSC';
	src: url('/asset/fonts/AlegreyaSansSC-Bold.ttf');
	font-weight: bold;
	font-style: normal; }

@font-face {
	font-family: 'CarroisGothicSC';
	src: url('/asset/fonts/CarroisGothicSC-Regular.ttf');
	font-weight: normal;
	font-style: normal; }





$primary-style:  "PingFangTC", "Lato", "Noto", "Helvetica","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif;

$secondary-style: "Lato", "Noto", "Helvetica","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;

// image mixin
@mixin image-formating($image) {
	background-image: url($image);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%; }



// shadows
$box-shadow: 1px 1px 6px 2px rgba(0,0,0, .2);
$inset-shadow: inset 0 0 0 1000px rgba(0,0,0,.3); // darkening of pictures
// $text-shadow: 2px 2px 6px rgba(20,27,45,.5)
// $text-shadow: 0 2px 4px rgba(0,0,0,.5)
$text-shadow: 0 3px 2px rgba(0, 0, 0, 0.11);
$text-shadow2: 0 2px 4px rgba(0, 0, 0, 0.5);
$text-shadow3: 0 2px 2px rgba(0, 0, 0, 0.2);


//breakpoints






