#bootstrapoverride {
	.nav-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 60px;
		z-index: 1000;
		min-width: 1200px;

		.nav-firsttime-guide-cover {
			z-index: 999;
			width: 50%;
			height: 100%;
			background-color: rgba(33, 40, 49, 0.86);
			position: absolute; }

		.itbd-logo {
			position: relative;
			left: 65px;
			top: 20px;
			margin-left: 25px;

			a {
				font-size: 24px;
				font-family: "CarroisGothicSC";
				letter-spacing: 1px;
				text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); } }

		.logo-decoration-line {
			width: 1px;
			height: 28px;
			position: absolute;
			background-color: rgba(255, 255, 255, 0.47); }

		.line-in-imarket {
			top: 17px;
			left: 177px; }

		.line-in-iindustry {
			top: 13px;
			left: 285px; } }

	.fixed-nav-background-color {
		background-color: rgba(33, 40, 49, .9); }

	.mobile-nav {
		position: absolute;
		top: 0;
		left: 0;
		width: 350px;
		height: 100vh;
		background-color: rgba(0,0,0,.8);
		z-index: 120;
		padding: 120px 75px;
		transition: left .5s;

		&.folded {
			left: -360px;
			pointer-events: none; }

		li {
			margin-bottom: 20px; } }


	// desktop navbar
	.desktop-nav {
		position: absolute;
		top: 20px;
		right: 50px;
		width: 600px;
		list-style-type: none {
	    margin: 0;
	    padding: 0; }

		&>li {
			display: inline-block;
			margin-right: 30px;

			&:first-of-type {
				margin-right: 50px; } }

		a {
			font-size: 16px;
			text-shadow: $text-shadow;
			letter-spacing: 1px; } }

	.lines-button.intro ~ .desktop-nav {
		top: 75px;
		right: 130px;
		width: auto;
		opacity: 1;
		transition: all .2s;

		&.folded {
			right: 0px;
			opacity: 0; }

		&>li {
			margin-right: 60px;

			&:first-of-type {
				margin-right: 60px; } }

		a {
			text-shadow: initial;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.8px;
			text-align: center;
			color: white; } }

	.dropdown-nav {
		position: relative;
		padding-bottom: 30px;

		&:hover .sub-menu {
			opacity: 1;
			display: initial; }


		.sub-menu {
			position: absolute;
			top: 35px;
			background-color: rgba(31, 31, 31, 0.95);
			border-radius: 4px;
			padding: 10px 10px;
			opacity: 0;
			display: none;
			transition: opacity .2s ease-in-out;

			.active-sub-menu {
				opacity: 1;
				display: initial; }

			a li {
				display: block;
				padding: 6px 20px;
				white-space: nowrap;
				font-size: 14px;
				transition: color .05s linear;

				&:hover {
					color: #ff6560; } }
			&:hover {
				opacity: 1;
				pointer-events: auto; }

			//arrow
			&:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				left: 50%;
				top: 0;
				margin-top: -6px;
				border-width: 0 6px 6px 6px;
				border-style: solid;
				border-color: rgba(31, 31, 31, 0.8) transparent; } }

		.sub-menu-1 {
			left: -60px; }
		.sub-menu-2 {
			left: -30px; } }



	//login button
	.login-btn {
		position: absolute;
		top: 35px;
		right: 70px;
		width: 100px;
		font-weight: bold; }



	.itbd-nav {
		max-width: 1200px;
		right: 0;
		margin: 0 auto;

		.desktop-nav {
			width: 300px; }

		li {
			a {
				letter-spacing: 1px; } } } }

@media all and (max-width: 1200px) {
	#bootstrapoverride {
		.nav-container {
			position: absolute; } } }
