@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
*, *:before, *:after {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'Noto';
  src: url("/asset/fonts/NotoSans-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Noto';
  src: url("/asset/fonts/NotoSans-Bold.ttf");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("/asset/fonts/Lato-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("/asset/fonts/Lato-Bold.ttf");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'AlegreyaSansSC';
  src: url("/asset/fonts/AlegreyaSansSC-Bold.ttf");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'CarroisGothicSC';
  src: url("/asset/fonts/CarroisGothicSC-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

#bootstrapoverride h1, #bootstrapoverride h2, #bootstrapoverride h3, #bootstrapoverride h4, #bootstrapoverride h5, #bootstrapoverride li, #bootstrapoverride a, #bootstrapoverride label, #bootstrapoverride p, #bootstrapoverride span, #bootstrapoverride div {
  font-family: "PingFangTC", "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
  color: white; }

#bootstrapoverride text {
  font-family: "PingFangTC", "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif; }

#bootstrapoverride li, #bootstrapoverride a, #bootstrapoverride label, #bootstrapoverride p, #bootstrapoverride span, #bootstrapoverride div {
  font-size: 14px; }

#bootstrapoverride h1 {
  font-size: 45px; }

#bootstrapoverride h2 {
  font-size: 37px;
  line-height: 45px; }

#bootstrapoverride h3 {
  font-size: 24px;
  line-height: 32px; }

#bootstrapoverride h4 {
  font-size: 20px;
  line-height: 28px; }

#bootstrapoverride h5 {
  font-size: 15px; }

#bootstrapoverride p {
  font-size: 14px;
  line-height: 26px;
  color: white; }

#bootstrapoverride input, #bootstrapoverride label {
  color: white; }

#bootstrapoverride option {
  color: black !important; }

#bootstrapoverride a,
#bootstrapoverride a:link,
#bootstrapoverride a:visited,
#bootstrapoverride a:hover,
#bootstrapoverride a:active {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit; }

#bootstrapoverride .link-btn {
  background-color: transparent;
  border-radius: 2px;
  border: solid 1px #ffffff;
  font-size: 10px;
  min-height: 30px;
  padding: 3px 26px 1px;
  margin: 30px auto;
  display: table;
  letter-spacing: 1.3px; }
  #bootstrapoverride .link-btn .large {
    font-size: 14px; }

#bootstrapoverride input:focus,
#bootstrapoverride textarea:focus {
  outline: none; }

#bootstrapoverride .nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  min-width: 1200px; }
  #bootstrapoverride .nav-container .nav-firsttime-guide-cover {
    z-index: 999;
    width: 50%;
    height: 100%;
    background-color: rgba(33, 40, 49, 0.86);
    position: absolute; }
  #bootstrapoverride .nav-container .itbd-logo {
    position: relative;
    left: 65px;
    top: 20px;
    margin-left: 25px; }
    #bootstrapoverride .nav-container .itbd-logo a {
      font-size: 24px;
      font-family: "CarroisGothicSC";
      letter-spacing: 1px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); }
  #bootstrapoverride .nav-container .logo-decoration-line {
    width: 1px;
    height: 28px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.47); }
  #bootstrapoverride .nav-container .line-in-imarket {
    top: 17px;
    left: 177px; }
  #bootstrapoverride .nav-container .line-in-iindustry {
    top: 13px;
    left: 285px; }

#bootstrapoverride .fixed-nav-background-color {
  background-color: rgba(33, 40, 49, 0.9); }

#bootstrapoverride .mobile-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 120;
  padding: 120px 75px;
  transition: left .5s; }
  #bootstrapoverride .mobile-nav.folded {
    left: -360px;
    pointer-events: none; }
  #bootstrapoverride .mobile-nav li {
    margin-bottom: 20px; }

#bootstrapoverride .desktop-nav {
  position: absolute;
  top: 20px;
  right: 50px;
  width: 600px;
  list-style-type: none;
    list-style-type-margin: 0;
    list-style-type-padding: 0; }
  #bootstrapoverride .desktop-nav > li {
    display: inline-block;
    margin-right: 30px; }
    #bootstrapoverride .desktop-nav > li:first-of-type {
      margin-right: 50px; }
  #bootstrapoverride .desktop-nav a {
    font-size: 16px;
    text-shadow: 0 3px 2px rgba(0, 0, 0, 0.11);
    letter-spacing: 1px; }

#bootstrapoverride .lines-button.intro ~ .desktop-nav {
  top: 75px;
  right: 130px;
  width: auto;
  opacity: 1;
  transition: all .2s; }
  #bootstrapoverride .lines-button.intro ~ .desktop-nav.folded {
    right: 0px;
    opacity: 0; }
  #bootstrapoverride .lines-button.intro ~ .desktop-nav > li {
    margin-right: 60px; }
    #bootstrapoverride .lines-button.intro ~ .desktop-nav > li:first-of-type {
      margin-right: 60px; }
  #bootstrapoverride .lines-button.intro ~ .desktop-nav a {
    text-shadow: initial;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.8px;
    text-align: center;
    color: white; }

#bootstrapoverride .dropdown-nav {
  position: relative;
  padding-bottom: 30px; }
  #bootstrapoverride .dropdown-nav:hover .sub-menu {
    opacity: 1;
    display: initial; }
  #bootstrapoverride .dropdown-nav .sub-menu {
    position: absolute;
    top: 35px;
    background-color: rgba(31, 31, 31, 0.95);
    border-radius: 4px;
    padding: 10px 10px;
    opacity: 0;
    display: none;
    transition: opacity .2s ease-in-out; }
    #bootstrapoverride .dropdown-nav .sub-menu .active-sub-menu {
      opacity: 1;
      display: initial; }
    #bootstrapoverride .dropdown-nav .sub-menu a li {
      display: block;
      padding: 6px 20px;
      white-space: nowrap;
      font-size: 14px;
      transition: color .05s linear; }
      #bootstrapoverride .dropdown-nav .sub-menu a li:hover {
        color: #ff6560; }
    #bootstrapoverride .dropdown-nav .sub-menu:hover {
      opacity: 1;
      pointer-events: auto; }
    #bootstrapoverride .dropdown-nav .sub-menu:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      top: 0;
      margin-top: -6px;
      border-width: 0 6px 6px 6px;
      border-style: solid;
      border-color: rgba(31, 31, 31, 0.8) transparent; }
  #bootstrapoverride .dropdown-nav .sub-menu-1 {
    left: -60px; }
  #bootstrapoverride .dropdown-nav .sub-menu-2 {
    left: -30px; }

#bootstrapoverride .login-btn {
  position: absolute;
  top: 35px;
  right: 70px;
  width: 100px;
  font-weight: bold; }

#bootstrapoverride .itbd-nav {
  max-width: 1200px;
  right: 0;
  margin: 0 auto; }
  #bootstrapoverride .itbd-nav .desktop-nav {
    width: 300px; }
  #bootstrapoverride .itbd-nav li a {
    letter-spacing: 1px; }

@media all and (max-width: 1200px) {
  #bootstrapoverride .nav-container {
    position: absolute; } }

body#bootstrapoverride {
  position: relative;
  background-color: #212831;
  z-index: -99999;
  min-width: 1200px; }
  body#bootstrapoverride.imarket {
    background: linear-gradient(to bottom, rgba(22, 34, 52, 0) 0%, #0e1826 45%, #162131 100%);
    background-color: #212831; }
  body#bootstrapoverride.gradient-intro-background {
    background-color: none;
    background: linear-gradient(to bottom, #19344a 0%, #1d2d3c 54%, #212831 100%); }
  body#bootstrapoverride button {
    font-family: "PingFangTC", "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif; }
  body#bootstrapoverride .main-content-wrapper {
    position: relative; }
  body#bootstrapoverride .chart-outer-wrapper {
    position: absolute;
    top: 0;
    left: 380px;
    width: calc(100% - 380px);
    margin-top: 100px;
    transition: transform .4s .1s ease-in-out; }
    body#bootstrapoverride .chart-outer-wrapper h1 {
      font-size: 25px;
      text-align: center;
      margin-bottom: 50px;
      line-height: 32px; }
      body#bootstrapoverride .chart-outer-wrapper h1 span {
        font-size: 25px; }
    body#bootstrapoverride .chart-outer-wrapper .chart-remark {
      position: relative;
      height: 55px; }
      body#bootstrapoverride .chart-outer-wrapper .chart-remark .chart-remark-inner {
        position: absolute;
        width: 250px;
        right: 50px;
        text-align: left; }
        body#bootstrapoverride .chart-outer-wrapper .chart-remark .chart-remark-inner p {
          font-size: 18px; }
    body#bootstrapoverride .chart-outer-wrapper .firsttime-guide {
      z-index: 1000;
      font-family: "PingFangTC";
      position: absolute;
      height: 120%;
      width: 100%;
      top: -100px;
      padding-left: 29px;
      background-color: rgba(33, 40, 49, 0.86); }
      body#bootstrapoverride .chart-outer-wrapper .firsttime-guide .guide-title {
        margin: 70px 0px 0px;
        font-size: 20px; }
      body#bootstrapoverride .chart-outer-wrapper .firsttime-guide .guide-steps {
        margin: 50px 0px;
        top: 49px; }
      body#bootstrapoverride .chart-outer-wrapper .firsttime-guide p {
        margin: 15px 0px;
        font-size: 20px; }
    body#bootstrapoverride .chart-outer-wrapper.expanded {
      transform: translate3d(-300px, 0, 0); }
    body#bootstrapoverride .chart-outer-wrapper.ranking-outer-wrapper {
      right: 0;
      margin: 100px auto 0; }
  body#bootstrapoverride .dark-button {
    width: 85px;
    height: 30px;
    font-size: 16px;
    border: solid 1px white;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    color: white;
    text-align: center;
    line-height: 0px;
    margin: 20px auto; }
    body#bootstrapoverride .dark-button:hover {
      background-color: #3b445c; }
    body#bootstrapoverride .dark-button.active {
      background-color: #3b445c; }
  body#bootstrapoverride.iindustry {
    background: none;
    background-color: #30334a; }
  body#bootstrapoverride .iindustry-nav {
    background-color: rgba(0, 0, 0, 0.1);
    height: 50px;
    padding-top: 15px; }
    body#bootstrapoverride .iindustry-nav.darker {
      background-color: #101634; }
    body#bootstrapoverride .iindustry-nav .inner-wrapper {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px; }
    body#bootstrapoverride .iindustry-nav .iindustry-logo {
      position: relative;
      left: 173px;
      top: 0px;
      margin-left: 25px; }
      body#bootstrapoverride .iindustry-nav .iindustry-logo a {
        font-size: 24px;
        font-family: "CarroisGothicSC";
        letter-spacing: 1px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); }
    body#bootstrapoverride .iindustry-nav .desktop-nav {
      position: static;
      float: right;
      width: auto;
      margin-right: 180px; }
      body#bootstrapoverride .iindustry-nav .desktop-nav li:first-of-type {
        margin-right: 60px; }
      body#bootstrapoverride .iindustry-nav .desktop-nav a {
        font-size: 14px;
        font-weight: bold;
        color: #70748c;
        transition: color .2s; }
        body#bootstrapoverride .iindustry-nav .desktop-nav a.active {
          position: relative;
          color: white; }
          body#bootstrapoverride .iindustry-nav .desktop-nav a.active:after {
            content: "";
            position: absolute;
            bottom: -15px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #50e3c2; }
        body#bootstrapoverride .iindustry-nav .desktop-nav a:hover {
          color: white; }
  body#bootstrapoverride .iindustry-body-wrapper {
    position: relative;
    padding-top: 250px; }
    body#bootstrapoverride .iindustry-body-wrapper .page-name {
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      font-size: 22px;
      line-height: 30px;
      font-weight: bold;
      text-align: center; }
      body#bootstrapoverride .iindustry-body-wrapper .page-name span {
        font-size: 22px; }
  body#bootstrapoverride .chart-quick-nav {
    position: absolute;
    top: 160px;
    left: 0;
    right: 0;
    width: 248px;
    margin: 0 auto; }
    body#bootstrapoverride .chart-quick-nav li {
      display: inline-block;
      position: relative;
      width: 80px;
      font-size: 14px;
      color: rgba(112, 117, 141, 0.6);
      text-align: center;
      transition: color .3s;
      font-weight: bold; }
      body#bootstrapoverride .chart-quick-nav li:after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: -1px;
        width: calc(100% + 2px);
        height: 1px;
        background-color: rgba(112, 117, 141, 0.6);
        transition: background-color .3s; }
      body#bootstrapoverride .chart-quick-nav li.active {
        color: white; }
        body#bootstrapoverride .chart-quick-nav li.active:after {
          background-color: #50e3c2; }
      body#bootstrapoverride .chart-quick-nav li:hover {
        cursor: pointer; }
  body#bootstrapoverride .bar-chart-legend {
    position: absolute;
    top: 210px;
    left: 0;
    right: 0;
    width: 870px;
    margin: 0 auto; }
    body#bootstrapoverride .bar-chart-legend .float-right {
      display: flex;
      float: right;
      margin-left: 45px; }
    body#bootstrapoverride .bar-chart-legend .circle {
      position: relative;
      display: inline-block;
      width: 26px;
      height: 26px;
      border: 2px solid #babed4;
      border-radius: 50%;
      margin-right: 8px; }
      body#bootstrapoverride .bar-chart-legend .circle:after {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: 16px;
        height: 16px;
        border-radius: 50%; }
      body#bootstrapoverride .bar-chart-legend .circle.red:after {
        background: linear-gradient(to top, #fe9957 0%, #ea7777 100%); }
      body#bootstrapoverride .bar-chart-legend .circle.blue:after {
        background: linear-gradient(to top, #31d6dc 0%, #209edc 100%); }
      body#bootstrapoverride .bar-chart-legend .circle.yellow:after {
        background: linear-gradient(to top, #ffe38c 0%, #ffd34d 100%); }
    body#bootstrapoverride .bar-chart-legend p {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      line-height: 29px; }
  body#bootstrapoverride .horizontal-stacked-container {
    margin-top: 80px; }
  body#bootstrapoverride .horizontal-chart-legend {
    position: absolute;
    top: 260px;
    left: 0;
    right: 0;
    width: 900px;
    margin: 0 auto; }
  body#bootstrapoverride .line-chart-container {
    margin-top: 80px; }
  body#bootstrapoverride .iindustry-chart-outer-wrapper {
    width: 1000px;
    margin: 0px auto 70px; }
  body#bootstrapoverride .chart-container {
    margin: 0 auto 40px; }
    body#bootstrapoverride .chart-container svg {
      margin: 0px auto;
      display: block; }
    body#bootstrapoverride .chart-container p {
      text-align: center; }
    body#bootstrapoverride .chart-container table {
      width: 770px;
      background-color: transparent;
      margin-bottom: 100px; }
    body#bootstrapoverride .chart-container td {
      border: none;
      font-weight: bold;
      padding: 5px 10px; }
    body#bootstrapoverride .chart-container thead {
      background-color: rgba(18, 27, 38, 0.8);
      color: rgba(255, 255, 255, 0.9); }
      body#bootstrapoverride .chart-container thead td {
        position: relative;
        padding: 5px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        width: 100px; }
        body#bootstrapoverride .chart-container thead td:nth-child(1) {
          width: 130px; }
        body#bootstrapoverride .chart-container thead td:not(:last-of-type):after {
          content: "";
          position: absolute;
          top: 8px;
          right: 0;
          width: 1px;
          height: 40px;
          background-color: rgba(255, 255, 255, 0.5); }
        body#bootstrapoverride .chart-container thead td:hover {
          cursor: pointer; }
      body#bootstrapoverride .chart-container thead tr:nth-child(2) td:after {
        background-color: rgba(255, 255, 255, 0); }
    body#bootstrapoverride .chart-container tbody > tr:first-of-type td {
      padding-top: 20px; }
    body#bootstrapoverride .chart-container tbody {
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto; }
      body#bootstrapoverride .chart-container tbody tr:nth-child(even) {
        background-color: rgba(18, 27, 38, 0.6); }
      body#bootstrapoverride .chart-container tbody tr td {
        text-align: right;
        width: 100px; }
        body#bootstrapoverride .chart-container tbody tr td:nth-child(1) {
          text-align: center;
          width: 130px; }
  body#bootstrapoverride .iindustry-table tbody {
    display: block; }
  body#bootstrapoverride .table-source {
    position: relative;
    width: 160px;
    top: -135px;
    margin: auto; }
  body#bootstrapoverride .line-chart-legend {
    position: absolute;
    top: 260px;
    left: 0;
    right: 0;
    width: 600px;
    margin: 0 auto; }
  body#bootstrapoverride .chart-controls {
    width: 770px;
    height: 90px;
    margin: 0 auto; }
    body#bootstrapoverride .chart-controls .vertical-separate {
      font-size: 18px;
      height: 30px;
      line-height: 30px;
      margin: 0 14px; }
    body#bootstrapoverride .chart-controls .btn {
      line-height: 30px;
      background: linear-gradient(to top, #31d6dc 0%, #209edc 100%);
      padding: 0 30px;
      border-radius: 2px;
      height: 30px; }
      body#bootstrapoverride .chart-controls .btn.file-upload-btn {
        background: linear-gradient(to top, #31d6dc 0%, #50e3c2 100%);
        float: right;
        height: 30px;
        line-height: 30px;
        padding: 0 30px;
        width: 96px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        body#bootstrapoverride .chart-controls .btn.file-upload-btn.has-file {
          padding: 0 15px; }
        body#bootstrapoverride .chart-controls .btn.file-upload-btn:hover {
          position: absolute;
          width: auto;
          padding: 0 30px;
          transition: all .2s; }
    body#bootstrapoverride .chart-controls .dropdown-wrapper,
    body#bootstrapoverride .chart-controls .btn {
      float: left; }
    body#bootstrapoverride .chart-controls .dropdown-wrapper {
      width: auto;
      margin-right: 10px; }
      body#bootstrapoverride .chart-controls .dropdown-wrapper:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 10px;
        top: 20px;
        margin-top: -6px;
        border-width: 6px 6px 0 6px;
        border-style: solid;
        border-color: white transparent; }
      body#bootstrapoverride .chart-controls .dropdown-wrapper select {
        width: 100%;
        height: 30px;
        padding: 2px 40px 2px 5px;
        border: solid 1px white;
        border-radius: 2px;
        appearance: none;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        font-size: 14px;
        color: white; }
    body#bootstrapoverride .chart-controls .hidden {
      display: none; }
  body#bootstrapoverride .bar-chart-container {
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex; }
  body#bootstrapoverride .iindustry-treemap-container {
    position: relative; }

#bootstrapoverride .body-wrapper .intro-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden; }
  #bootstrapoverride .body-wrapper .intro-wrapper .particle-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 600px;
    height: 600px;
    margin: 0 auto;
    border-radius: 50%;
    width: 100%;
    height: 100vh;
    z-index: -9999; }
  #bootstrapoverride .body-wrapper .intro-wrapper .slogan {
    position: fixed;
    top: 40vh;
    left: 30%; }
    #bootstrapoverride .body-wrapper .intro-wrapper .slogan span {
      font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
      font-size: 24px;
      line-height: 30px; }
    #bootstrapoverride .body-wrapper .intro-wrapper .slogan .italic {
      font-style: italic; }
    #bootstrapoverride .body-wrapper .intro-wrapper .slogan .heavy {
      font-weight: bold; }
  #bootstrapoverride .body-wrapper .intro-wrapper .down-arrow {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 85px;
    height: 62px;
    margin: 0 auto;
    z-index: 1;
    transition: transform .3s;
    cursor: pointer; }
    #bootstrapoverride .body-wrapper .intro-wrapper .down-arrow:before {
      content: "Learn More";
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      font-size: 14px;
      letter-spacing: .8px;
      font-weight: 500;
      font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
      text-align: center;
      animation-name: textJump;
      animation-duration: 2s;
      animation-iteration-count: infinite; }
    #bootstrapoverride .body-wrapper .intro-wrapper .down-arrow:after {
      content: "";
      position: absolute;
      top: 32px;
      left: 50%;
      border-left: solid 1px white;
      height: 30px;
      width: 1px;
      animation-name: arrowWave;
      animation-duration: 2s;
      animation-iteration-count: infinite; }
    #bootstrapoverride .body-wrapper .intro-wrapper .down-arrow.remove {
      transform: translate3d(0, 10vh, 0); }
  #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask {
    position: fixed;
    top: 110vh;
    left: 0;
    right: 0;
    min-width: 1200px;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.8);
    transition: transform .5s; }
    #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask.move-to-view {
      transform: translate3d(0px, -110vh, 0) !important; }
    #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .projects-wrapper {
      min-width: 100vw;
      margin: 0px auto 0px;
      height: 100vh;
      overflow: auto;
      padding: 25vh 15vw 10vh 15vw;
      display: flex;
      flex-wrap: wrap; }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .projects-wrapper .news {
        margin: 0px 70px 0px 70px;
        width: 100%;
        height: 75vh; }
    #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box {
      position: relative;
      float: left;
      width: 300px;
      height: 400px;
      margin: 0 15px 15px;
      padding: 95px 25px;
      border: solid 1px rgba(255, 255, 255, 0.9); }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box .triangle {
        width: 130px;
        height: 130px;
        position: absolute;
        clip: rect(auto, 180px, auto, 100px);
        transform: rotate(-135deg);
        opacity: 0;
        transition: opacity .3s; }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box .triangle::after {
        content: "";
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        background: linear-gradient(to top left, #209edc 0%, #31d6dc 40%);
        transform: rotate(-45deg); }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box .triangle-1 {
        top: -11px;
        left: -11px; }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box .triangle-2 {
        bottom: -11px;
        right: -11px;
        transform: rotate(45deg); }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box h2 {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 1px;
        margin-bottom: 15px;
        font-family: "CarroisGothicSC", "微軟正黑體"; }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box p {
        line-height: 22px;
        font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif; }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box .more {
        position: absolute;
        bottom: 30px;
        left: 25px;
        color: #2ecbd0;
        font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
        font-weight: bold;
        opacity: 0;
        transition: opacity .3s; }
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box:hover .more,
      #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box:hover .triangle {
        opacity: 1; }
    #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .project-box.inactive {
      pointer-events: none; }
    #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .footer, #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .footer a {
      width: 100%;
      margin: 5vh 0 -5vh 0;
      text-align: center;
      letter-spacing: 1px;
      font-size: 18px;
      font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif; }
    #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask .copy {
      opacity: .9;
      width: 100%;
      margin: 10vh 0 calc(-10vh + 26px) 0;
      text-align: center;
      letter-spacing: 1px;
      font-size: 18px;
      font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif; }
  #bootstrapoverride .body-wrapper .intro-wrapper .main-container {
    width: 874px;
    margin: 170px auto; }
  #bootstrapoverride .body-wrapper .intro-wrapper .about-us {
    margin: calc(50vh - 70px) auto; }
    #bootstrapoverride .body-wrapper .intro-wrapper .about-us p {
      line-height: 1.86;
      letter-spacing: .8px;
      text-align: justify; }
  #bootstrapoverride .body-wrapper .intro-wrapper .news table {
    margin: 0; }
  #bootstrapoverride .body-wrapper .intro-wrapper .news table, #bootstrapoverride .body-wrapper .intro-wrapper .news td {
    padding: 0;
    border: none;
    background-color: transparent; }
  #bootstrapoverride .body-wrapper .intro-wrapper .news td {
    text-align: left;
    line-height: 14px;
    letter-spacing: .8px; }
    #bootstrapoverride .body-wrapper .intro-wrapper .news td:first-of-type {
      padding-right: 30px; }
    #bootstrapoverride .body-wrapper .intro-wrapper .news td:nth-of-type(2) {
      width: 701px;
      padding-bottom: 15px; }
    #bootstrapoverride .body-wrapper .intro-wrapper .news td a {
      text-decoration: underline; }
      #bootstrapoverride .body-wrapper .intro-wrapper .news td a:after {
        position: absolute;
        top: 0;
        right: -60px;
        content: "More";
        width: 55px;
        height: 24px;
        background-color: #ff6560;
        border-radius: 3px;
        text-align: center;
        line-height: 1.71;
        font-family: "CarroisGothicSC", "微軟正黑體"; }
  #bootstrapoverride .body-wrapper .intro-wrapper .news tr {
    margin-bottom: 15px; }
  #bootstrapoverride .body-wrapper .intro-wrapper .iresource {
    display: flex;
    flex-direction: column; }
    #bootstrapoverride .body-wrapper .intro-wrapper .iresource .link-container {
      width: 199px;
      height: 170px;
      margin: 0 24px 50px -15px; }
      #bootstrapoverride .body-wrapper .intro-wrapper .iresource .link-container img {
        width: 100%;
        margin-bottom: 6px;
        vertical-align: top; }
      #bootstrapoverride .body-wrapper .intro-wrapper .iresource .link-container .name {
        width: 100%;
        height: 84px;
        line-height: 1.5; }
  #bootstrapoverride .body-wrapper .intro-wrapper .description .video-container {
    margin: 0 0 0px 50px;
    min-height: 100px;
    overflow: hidden; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description .video-container iframe {
      width: 375px;
      height: 211px;
      float: left;
      margin: 9px; }
  #bootstrapoverride .body-wrapper .intro-wrapper .description table {
    width: calc(100% - 50px);
    margin: 0 0 60px 50px;
    background-color: transparent;
    border: none;
    table-layout: fixed; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table th, #bootstrapoverride .body-wrapper .intro-wrapper .description table td {
      background-color: transparent;
      border: none;
      text-align: left;
      padding: 8px;
      word-break: break-all; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table.resource th:first-child, #bootstrapoverride .body-wrapper .intro-wrapper .description table.resource td:first-child {
      width: 30%; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table.resource th:nth-child(2), #bootstrapoverride .body-wrapper .intro-wrapper .description table.resource td:nth-child(2) {
      width: 20%; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table.resource th:nth-child(3), #bootstrapoverride .body-wrapper .intro-wrapper .description table.resource td:nth-child(3) {
      width: 30%; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table.resource th:nth-child(4), #bootstrapoverride .body-wrapper .intro-wrapper .description table.resource td:nth-child(4) {
      width: 20%; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table.hscode th:first-child, #bootstrapoverride .body-wrapper .intro-wrapper .description table.hscode td:first-child {
      width: 30%; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table.hscode th:nth-child(2), #bootstrapoverride .body-wrapper .intro-wrapper .description table.hscode td:nth-child(2) {
      width: 55%; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table.hscode th:nth-child(3), #bootstrapoverride .body-wrapper .intro-wrapper .description table.hscode td:nth-child(3) {
      width: 15%; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table.hscode caption {
      color: white;
      font-weight: bold; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table th {
      border-bottom: solid 1px rgba(255, 255, 255, 0.37); }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table td.indent {
      padding-left: 40px; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table td, #bootstrapoverride .body-wrapper .intro-wrapper .description table tr {
      transition: all .2s; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description table tbody tr:hover {
      background-color: rgba(13, 16, 20, 0.67); }
      #bootstrapoverride .body-wrapper .intro-wrapper .description table tbody tr:hover .too-long-code {
        text-decoration: underline;
        cursor: pointer; }
        #bootstrapoverride .body-wrapper .intro-wrapper .description table tbody tr:hover .too-long-code.all {
          text-decoration: none;
          white-space: normal; }
  #bootstrapoverride .body-wrapper .intro-wrapper .description .illustation tbody tr:hover {
    background-color: transparent; }
  #bootstrapoverride .body-wrapper .intro-wrapper .description .illustation img {
    width: 452px;
    height: auto; }
  #bootstrapoverride .body-wrapper .intro-wrapper .description .illustation tbody tr {
    padding: 0px 40px 30px 0px; }
  #bootstrapoverride .body-wrapper .intro-wrapper .description .illustation tbody td {
    vertical-align: middle;
    line-height: 1.86;
    letter-spacing: 0.8px; }
    #bootstrapoverride .body-wrapper .intro-wrapper .description .illustation tbody td:first-child {
      width: 500px; }
  #bootstrapoverride .body-wrapper .intro-wrapper .description .illustation .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.86;
    letter-spacing: 0.8px; }
  #bootstrapoverride .body-wrapper .intro-wrapper .title {
    font-size: 24px;
    letter-spacing: 1.3px;
    margin-bottom: 28px; }
    #bootstrapoverride .body-wrapper .intro-wrapper .title .itbd-logo {
      font-size: 24px;
      letter-spacing: 1.3px;
      font-family: "CarroisGothicSC", "微軟正黑體"; }

.down-arrow-2 {
  display: block;
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  width: 57px;
  height: 62px;
  margin: 0 auto;
  z-index: 1;
  transition: transform .3s;
  cursor: pointer; }
  .down-arrow-2:before {
    content: "Project";
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    font-size: 14px;
    letter-spacing: .8px;
    font-weight: 500;
    font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
    text-align: center;
    animation-name: textJump;
    animation-duration: 2s;
    animation-iteration-count: infinite; }
  .down-arrow-2:after {
    content: "";
    position: absolute;
    top: 32px;
    left: 50%;
    border-left: solid 1px white;
    height: 30px;
    width: 1px;
    animation-name: arrowWave;
    animation-duration: 2s;
    animation-iteration-count: infinite; }
  .down-arrow-2.remove {
    transform: translate3d(0, 40vh, 0); }

@media all and (max-width: 1200px) {
  #bootstrapoverride .body-wrapper .intro-wrapper .projects-mask {
    position: absolute; } }

@keyframes arrowWave {
  0% {
    height: 1px;
    top: 32px; }
  50% {
    height: 20px;
    top: 32px; }
  75% {
    height: 10px;
    top: 46px; }
  100% {
    height: 0px;
    top: 62px; } }

@keyframes textJump {
  0% {
    top: 0px; }
  75% {
    top: 0px; }
  95% {
    top: 10px; }
  100% {
    top: 0px; } }

#bootstrapoverride .home-container {
  position: relative;
  width: 100%;
  padding-bottom: 30px; }
  #bootstrapoverride .home-container .video-bg {
    width: 100%;
    height: 750px;
    background: linear-gradient(to bottom, rgba(22, 34, 52, 0.4) 0%, rgba(14, 24, 38, 0.4) 70%, rgba(14, 24, 38, 0.95) 90%, #0e1826 100%); }
  #bootstrapoverride .home-container .home-headers {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px; }
    #bootstrapoverride .home-container .home-headers h1, #bootstrapoverride .home-container .home-headers span {
      color: white; }
    #bootstrapoverride .home-container .home-headers .title {
      position: absolute;
      display: block;
      top: 230px;
      left: 0;
      right: 0;
      width: 400px;
      letter-spacing: 1px;
      margin: 0 auto;
      text-align: center; }
      #bootstrapoverride .home-container .home-headers .title span.big {
        font-size: 35px;
        font-weight: bold;
        text-shadow: 0 3px 2px rgba(0, 0, 0, 0.11); }
      #bootstrapoverride .home-container .home-headers .title span.small {
        display: inline-block;
        font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
        font-weight: normal;
        font-size: 24px;
        margin-top: 15px;
        text-shadow: 0 3px 2px rgba(0, 0, 0, 0.11); }
        #bootstrapoverride .home-container .home-headers .title span.small:after {
          content: "";
          position: absolute;
          bottom: -30px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 30px;
          height: 2px;
          background-color: white; }
    #bootstrapoverride .home-container .home-headers .sub-title {
      position: absolute;
      top: 340px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      font-size: 16px; }
    #bootstrapoverride .home-container .home-headers .taiwan-info-container {
      position: absolute;
      display: flex;
      align-content: space-between;
      top: 450px;
      left: 0;
      right: 0;
      width: 900px;
      margin: 0 auto; }
      #bootstrapoverride .home-container .home-headers .taiwan-info-container > div {
        flex: 1 1 auto;
        width: 25%;
        text-align: center; }
      #bootstrapoverride .home-container .home-headers .taiwan-info-container .small,
      #bootstrapoverride .home-container .home-headers .taiwan-info-container .large {
        display: block;
        text-shadow: 0 3px 2px rgba(0, 0, 0, 0.11); }
      #bootstrapoverride .home-container .home-headers .taiwan-info-container .small {
        font-size: 20px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }
      #bootstrapoverride .home-container .home-headers .taiwan-info-container .large {
        font-size: 36px;
        font-family: "Lato", "Noto", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
        margin-top: 20px;
        color: white;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); }
  #bootstrapoverride .home-container .home-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    padding: 30px 40px;
    margin: 0 auto; }
    #bootstrapoverride .home-container .home-content .column {
      flex: 1 1 auto;
      width: 20%;
      min-width: 200px;
      margin: 0 20px; }
      #bootstrapoverride .home-container .home-content .column h2, #bootstrapoverride .home-container .home-content .column h3 {
        position: relative; }
      #bootstrapoverride .home-container .home-content .column h2:after, #bootstrapoverride .home-container .home-content .column h3:after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 20px;
        height: 2px;
        background-color: white; }
    #bootstrapoverride .home-container .home-content .column-main {
      flex: 1 1 auto;
      width: 50%;
      margin: 0 50px 160px 20px; }
    #bootstrapoverride .home-container .home-content .column-img {
      position: relative;
      flex: 1 1 auto;
      width: 30%;
      margin-top: -80px;
      text-align: center;
      opacity: .9; }
    #bootstrapoverride .home-container .home-content h2 {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 20px; }
    #bootstrapoverride .home-container .home-content h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px; }
  #bootstrapoverride .home-container .copyright {
    text-align: center;
    margin-top: 145px;
    width: 100%;
      width-background: #212831;
      width-padding: 30px 0 0 0;
      width-text-align: center;
      width-color: white;
      width-font-size: 0.81em;
      width-line-height: 20px;
      width-margin-top: 100px; }

.hidden {
  display: none; }

.tick-hidden line {
  visibility: hidden; }

.bar-chart-container .axis--x text {
  transform: translate(0, 5px); }

#bootstrapoverride .toolbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  min-width: 200px;
  margin: 150px 40px 50px 40px;
  transition: transform .5s ease-in-out; }
  #bootstrapoverride .toolbar.hidden-toolbar {
    transform: translate3d(-340px, 0, 0); }
  #bootstrapoverride .toolbar h5 {
    color: white;
    font-weight: bold;
    margin-bottom: 15px; }
  #bootstrapoverride .toolbar .firsttime-guide-cover {
    z-index: 100;
    position: absolute;
    top: -40px;
    height: 150%;
    width: 100%; }
  #bootstrapoverride .toolbar .toolbar-title {
    position: absolute;
    top: -40px;
    left: 0;
    font-size: 18px; }
  #bootstrapoverride .toolbar .toolbar-section {
    padding: 20px;
    border-bottom: solid 1px #6b7389;
    border-style: solid;
    border-color: rgba(163, 173, 203, 0.6);
    border-width: 1px 1px 0 1px; }
  #bootstrapoverride .toolbar .aggregate-level-filter {
    border-bottom: none; }
  #bootstrapoverride .toolbar .double-chevron {
    position: absolute;
    top: -30px;
    right: 0;
    width: 24px;
    padding: 2px 4px;
    transition: all .5s ease-in-out; }
    #bootstrapoverride .toolbar .double-chevron:hover {
      cursor: pointer; }
    #bootstrapoverride .toolbar .double-chevron span:first-of-type {
      margin-right: 9px; }
    #bootstrapoverride .toolbar .double-chevron.left {
      transform: rotate(180deg); }
    #bootstrapoverride .toolbar .double-chevron.extend-right {
      right: -30px; }

#bootstrapoverride .toolbar .year-slider h5,
#bootstrapoverride .toolbar .month-slider h5 {
  margin-bottom: 55px; }

#bootstrapoverride #year-slider,
#bootstrapoverride #month-slider {
  margin: 0 auto; }

#bootstrapoverride .ui-slider-horizontal {
  height: 3px;
  width: 85% !important;
  border: none;
  background-color: #bcc8c9; }
  #bootstrapoverride .ui-slider-horizontal .ui-slider-range {
    background-color: #ff6560; }
  #bootstrapoverride .ui-slider-horizontal .ui-slider-handle {
    top: -6px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: white;
    border: 3px solid #ff6560; }
    #bootstrapoverride .ui-slider-horizontal .ui-slider-handle:focus {
      outline: none; }

#bootstrapoverride .slider-val-display {
  position: absolute;
  top: -32px;
  width: 46px;
  margin-left: -18px; }
  #bootstrapoverride .slider-val-display input {
    width: 100%;
    height: 18px;
    border: 0;
    border-radius: 1px;
    background-color: #ff6560;
    padding: 2px 0 1px;
    text-align: center;
    font-size: 12px;
    font-weight: bold; }

#bootstrapoverride .bubble-arrow:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 18px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 5px;
  border-color: #ff6560 transparent transparent transparent; }

#bootstrapoverride .slider-scale {
  position: relative;
  width: 85%;
  margin: 10px auto 50px; }
  #bootstrapoverride .slider-scale .slider-scale-val {
    position: absolute;
    top: 0;
    width: 40px;
    background-color: transparent;
    border: none;
    text-align: center;
    font-size: 10px;
    color: rgba(224, 224, 224, 0.5); }
    #bootstrapoverride .slider-scale .slider-scale-val.minval {
      left: -20px; }
    #bootstrapoverride .slider-scale .slider-scale-val.maxval {
      right: -20px; }
  #bootstrapoverride .slider-scale .scale-start,
  #bootstrapoverride .slider-scale .scale-end {
    position: absolute;
    top: -18px;
    width: 1px;
    height: 13px;
    background-color: #bcc8c9; }
  #bootstrapoverride .slider-scale .scale-start {
    left: 0; }
  #bootstrapoverride .slider-scale .scale-end {
    right: 0; }

#bootstrapoverride .toolbar .time-filter input[type="radio"],
#bootstrapoverride .toolbar .region-filter input[type="radio"] {
  display: none; }

#bootstrapoverride .toolbar .time-filter label:not(.file-upload-btn),
#bootstrapoverride .toolbar .region-filter label:not(.file-upload-btn) {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  line-height: 20px;
  margin: 0 0 5px 15px; }

#bootstrapoverride .toolbar .time-filter label:not(.file-upload-btn):before,
#bootstrapoverride .toolbar .region-filter label:not(.file-upload-btn):before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid white; }

#bootstrapoverride .toolbar .time-filter input:checked + label:not(.file-upload-btn):after,
#bootstrapoverride .toolbar .region-filter input:checked + label:not(.file-upload-btn):after {
  content: "";
  position: absolute;
  top: 6px;
  left: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ff6560; }

#bootstrapoverride .toolbar select {
  width: 100%;
  height: 30px;
  padding: 5px 9px;
  border: solid 1px #e1e1e1;
  border-radius: 2px;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  color: rgba(224, 224, 224, 0.5); }

#bootstrapoverride .dropdown-wrapper {
  position: relative;
  width: 90%;
  height: 30px;
  margin: 0 auto 10px; }
  #bootstrapoverride .dropdown-wrapper:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 15px;
    top: 20px;
    margin-top: -6px;
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: #e0e0e0 transparent; }

#bootstrapoverride .btn {
  display: inline-block;
  appearance: none;
  border: none;
  padding: 5px 20px;
  background-color: #ff6560;
  color: white;
  font-size: 18px;
  text-align: center; }
  #bootstrapoverride .btn:hover {
    cursor: pointer; }
  #bootstrapoverride .btn.build-chart {
    width: 100%;
    padding: 10px 0;
    letter-spacing: 5px; }

#bootstrapoverride .chevron-right {
  position: relative; }
  #bootstrapoverride .chevron-right:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 7px;
    border-color: transparent transparent transparent white; }
  #bootstrapoverride .chevron-right:after {
    content: "";
    position: absolute;
    top: 2px;
    left: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px;
    border-color: transparent transparent transparent #212831; }

#bootstrapoverride .lines-button {
  position: fixed;
  top: 30px;
  left: 60px;
  z-index: 9999;
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 8px 10px 15px;
  background: transparent;
  transition: .3s;
  cursor: pointer;
  user-select: none;
  appearance: none;
  border: none;
  opacity: 1; }
  #bootstrapoverride .lines-button.intro {
    top: 60px;
    left: initial;
    right: 80px; }
  #bootstrapoverride .lines-button:focus {
    outline: 0; }
  #bootstrapoverride .lines-button.hidden {
    display: none; }

#bootstrapoverride .lines {
  display: inline-block;
  width: 30px;
  height: 3px;
  background: white;
  transition: 0.3s;
  border-radius: 3px;
  position: relative;
  /*create the upper and lower lines as pseudo-elements of the middle line*/ }
  #bootstrapoverride .lines:before, #bootstrapoverride .lines:after {
    display: inline-block;
    width: 30px;
    height: 3px;
    background: white;
    transition: 0.3s;
    border-radius: 3px;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 2.1428571429px center; }
  #bootstrapoverride .lines:before {
    top: 10px; }
  #bootstrapoverride .lines:after {
    top: -10px; }

#bootstrapoverride .lines-button.intro .lines {
  height: 2px; }
  #bootstrapoverride .lines-button.intro .lines:before {
    top: 6.6px;
    height: 1.9px;
    width: 20.6px;
    right: 0;
    left: initial; }
  #bootstrapoverride .lines-button.intro .lines:after {
    top: -6.6px;
    height: 1.9px; }

#bootstrapoverride .lines-button.x.close .lines {
  /*hide the middle line*/
  background: transparent;
  /*overlay the lines by setting both their top values to 0*/ }
  #bootstrapoverride .lines-button.x.close .lines:before, #bootstrapoverride .lines-button.x.close .lines:after {
    transform-origin: 50% 50%;
    top: 0;
    width: 30px; }
  #bootstrapoverride .lines-button.x.close .lines:before {
    transform: rotate3d(0, 0, 1, 45deg); }
  #bootstrapoverride .lines-button.x.close .lines:after {
    transform: rotate3d(0, 0, 1, -45deg); }

#bootstrapoverride .btn-load {
  background-color: #ff6560;
  border: none;
  border-radius: 4px;
  float: right; }

#bootstrapoverride .file-upload {
  display: none; }

#bootstrapoverride .file-upload-btn {
  background-color: #8760ff;
  border: none;
  border-radius: 4px;
  float: right;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  width: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  #bootstrapoverride .file-upload-btn.has-file {
    padding: 0 10px; }
  #bootstrapoverride .file-upload-btn:hover {
    position: absolute;
    right: 21px;
    width: auto;
    padding: 0 20px;
    transition: all .2s; }

#bootstrapoverride g text {
  stroke-width: 0;
  fill: white;
  pointer-events: none; }

#bootstrapoverride g rect {
  shape-rendering: crispEdges;
  opacity: .9; }
  #bootstrapoverride g rect:hover {
    opacity: 1; }

#bootstrapoverride .chart-line {
  opacity: .9; }

#bootstrapoverride .line {
  fill: none;
  stroke: white;
  opacity: .7;
  stroke-width: 2px; }

#bootstrapoverride .tick text {
  font-size: 14px;
  fill: rgba(205, 213, 236, 0.8); }

#bootstrapoverride .tick line {
  opacity: .2; }

#bootstrapoverride .axis path,
#bootstrapoverride .axis line {
  fill: none;
  stroke: white;
  opacity: .2;
  shape-rendering: crispEdges; }

#bootstrapoverride .axis.x text {
  transform: translate(0, 10px); }

#bootstrapoverride .axis.y text {
  transform: translate(-10px, 0); }

#bootstrapoverride .y-label {
  transform: translate(-80px, 0) rotate(-90deg); }

#bootstrapoverride .label {
  fill: rgba(231, 234, 243, 0.8);
  font-size: 16px; }

#bootstrapoverride .area {
  opacity: .6; }

#bootstrapoverride .tooltip {
  position: absolute;
  max-width: 300px;
  padding: 20px;
  pointer-events: none;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: Helvetica;
  font-weight: bold; }

#bootstrapoverride .dot {
  opacity: .8; }

#bootstrapoverride .world-map-container path {
  stroke: grey;
  stroke-width: 0;
  fill: grey; }

#bootstrapoverride table {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 auto; }
  #bootstrapoverride table td {
    position: relative;
    border: 1px solid grey;
    padding: 5px 5px;
    text-align: center; }

#bootstrapoverride .countr-ranking-container table td {
  position: relative;
  border: 1px solid grey;
  padding: 5px 5px;
  text-align: center; }
  #bootstrapoverride .countr-ranking-container table td:first-of-type {
    padding: 5px 20px 5px 40px; }

#bootstrapoverride .chart-container-for-stacked-area {
  position: relative;
  padding-bottom: 50px; }
  #bootstrapoverride .chart-container-for-stacked-area table thead {
    background-color: #212831; }

#bootstrapoverride .focus-line {
  fill: white;
  opacity: 0;
  pointer-events: none; }

#bootstrapoverride .f32 {
  display: inline;
  position: absolute;
  top: -4px;
  left: 0; }
  #bootstrapoverride .f32 li.flag {
    transform: scale(0.8); }
