.hidden {
	display: none; }

.tick-hidden line {
	visibility: hidden; }


// #world-map-outer-wrapper
// 	width: 900px

.bar-chart-container .axis--x text {
	transform: translate(0, 5px); }














