
#bootstrapoverride {
	h1, h2, h3, h4, h5, li, a, label, p, span, div {
		font-family: $primary-style;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 22px;
		color: white; }


	text {
		font-family: $primary-style; }


	li, a, label, p, span, div {
		font-size: 14px; }


	h1 {
		font-size: 45px; }
	h2 {
		font-size: 37px;
		line-height: 45px; }
	h3 {
		font-size: 24px;
		line-height: 32px; }
	h4 {
		font-size: 20px;
		line-height: 28px; }
	h5 {
		font-size: 15px; }


	p {
		font-size: 14px;
		line-height: 26px;
		color: white; }


	input, label {
		color: white; }

	option {
		color: black !important; } //fixes bug for windows where select color changes font color on the dropdown

	a,
	a:link,
	a:visited,
	a:hover,
	a:active {
		color: inherit;
		text-decoration: inherit;
		font-weight: inherit; }

	.link-btn {
		background-color: transparent;
		border-radius: 2px;
		border: solid 1px #ffffff;
		font-size: 10px;
		min-height: 30px;
		padding: 3px 26px 1px;
		margin: 30px auto;
		display: table;
		letter-spacing: 1.3px;
		.large {
			font-size: 14px; } }

	input:focus,
	textarea:focus {
		outline: none; } }





